
























































import {namespace} from 'vuex-class';
import {Component} from 'vue-property-decorator';
import {AUTH_STORE_NAME, AuthStoreActions} from '@/store/auth.store';
import {ROUTE_HOME} from '@/router/routes';
import {email, required} from 'vuelidate/lib/validators';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {mixins} from 'vue-class-component';
import User from "@/models/User.model";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  mixins: [validationMixin],
  validations: {
    email: { required, email},
    password: { required }
  }
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin) {
  @AuthStore.Action(AuthStoreActions.LOGIN)
  private loginAction!: (payload: { email: string, password: string }) => Promise< User | null>;

  /**
   * v-model for the email, stores the current entered value of the email field
   * @private
   */
  private email: string = '';

  /**
   * v-model for the password, stores the current entered value of the email field
   * @private
   */
  private password: string = '';

  /**
   * flag that shows and hides the chars of the password field
   */
  private hidePassword: boolean = true;

  /**
   * redirects the user to the home view if he/she is logged in
   * @private
   */
  private created() {
    // Redirect to home if already logged in:
    if (this.$isLoggedIn()) {
      this.$router.push({ name: ROUTE_HOME });
    }
  }

  /**
   * is called when login button is tapped, validates login of the user
   * @private
   */
  private async doLogin() {
    // Trigger validation
    this.$v.$touch();

    // Form is Invalid return here
    if (this.$v.$invalid) {
      return;
    }

    try {
      // logs in the user, checks if the user has the correct role
      const user = await this.loginAction({email: this.email, password: this.password});
      if(!user) {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.UNAUTHORIZED_ROLE');
        return;
      }

      // navigates user to home view
      return this.$router.push({name: ROUTE_HOME});
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 400:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_WRONG_CREDENTIALS');
            break;
          case 401:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_USER_NOT_EXIST');
            break;
          case 403: // User not verified or not active
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_USER_NOT_VERIFIED_OR_ACTIVE');
            break;
          case 422: // E-Mail not registered or wrong e-mail/password
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_WRONG_CREDENTIALS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.LOGIN_ERROR');
        }
      });
    }
  }
}
